@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f5f7fa;
}

.container {
    margin-top: 40px;
}

.card {
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-primary {
    background-color: #2c3e50;
    border: none;
}

/* Loading animation styles */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #2c3e50;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: auto;
}

.course-image {
    border-radius: 15px 15px 0 0;
    width: 100%;              /* ensures the image takes the full width of its container */
    height: 100px;            /* set a fixed height */
    object-fit: cover;        /* this ensures that while scaling the image maintains its aspect ratio */
    display: block;           /* to remove any unwanted margins */
}

.user-profile {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #f6f8fa; /* Light grey */
}

.profile-pic {
    width: 60px;
    height: 60px;
    border-radius: 50%;  /* Circular image */
    margin-right: 20px;
}

.username {
    color: #0366d6; /* Blue */
}

.user-details p {
    color: #586069; /* Dark gray */
    font-size: 0.9em;
}


.column {
    flex: 1;  /* Each column takes up an equal amount of space */
    padding: 0px 20px;  /* Some spacing between columns */
    max-width: 5000px;
    width: 100%;
    flex: none;
    

    
}

.test {
    flex: 1;  /* Each column takes up an equal amount of space */
    padding: 0 20px;  /* Some spacing between columns */
    max-width: 800px;
    width: 800px;
    flex: none;
}


.home-screen {
    display: flex;
    /* justify-content: space-between; */
    /* align-items: stretch; */
    /* flex-direction: row; */
}







@media (max-width: 768px) {
    .home-screen {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        
    }

    .column {
        
        padding-left: 10px;
        padding-right: 10px;
        max-width: 100%;
        border: none;
        
    }


}

@media only screen and (min-width : 524px) {
    .unit {
        width: 500px;
    }
}


@media only screen and (min-width : 1224px) {
    .unit {
        width: 900px;
    }
}





.scale-hover-10 {
    @apply transition-all hover:scale-110 
}

.scale-hover-05 {
    @apply transition-all hover:scale-105
}


.unit-hover {
    @apply transition-all hover:shadow-lg
}


.icon-container:hover {
    transition: transform 0.3s;
}

.hover:-translate-y-1:hover {
    transform: translateY(-5px);
}

.hover:spinnnn:hover {
    transform: translateY(-5px);
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* You define the normal state and a transition that will last 2s */
.icon-spin {
    transform: rotate(0deg);
    transition: transform 1s linear;
}

/* Upon hovering, the icon will smoothly rotate. */
.icon-spin:hover {
    transform: rotate(360deg);
    /* You keep the same duration and timing function (linear) here */
}



.icon-user-friends {
    transform: scale(1);
    transition: transform 0.5s ease-in-out;
}

.icon-user-friends:hover {
    transform: scale(1.6);  /* You can adjust the growth amount as needed */
}


@keyframes bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-5px); }
    60% { transform: translateY(-2.5px); }
}

.icon-comments:hover {
    animation: bounce 1s;
}
